import axios from "axios";
import store from "@/pages/main/store";
const response_interceptors = [async function (response) {
  let companyID = null;
  const newToken = response.headers['new-token'];
  if (newToken) {
    let reqUrl = response.config.url;
    store.dispatch('auth/setToken', newToken);
    base.defaults.headers.common['Authorization'] = newToken;
    if (reqUrl.includes('/companies/profile') && response.data?.companyID) companyID = response.data.companyID;
    store.dispatch('auth/fetchUser', companyID);
  }
  return response;
}];
const request_interceptors = [function (config) {
  return config;
}];
const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 1000 * 60,
  // 1 minute
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});
response_interceptors.forEach(element => {
  base.interceptors.response.use(element);
});
request_interceptors.forEach(element => {
  base.interceptors.request.use(element);
});
export default base;