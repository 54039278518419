import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "relative overflow-hidden h-1.5 mb-2 text-xs flex rounded-sm bg-primary-200 transition-all w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    style: _normalizeStyle({
      width: `${!isNaN($props.value) ? $props.value * 1 : $props.indeterminate ? 50 : 0}%`
    }),
    class: _normalizeClass(["absolute top-0 shadow-none h-full bg-pink-500", {
      '-left-1/2 animate-loading-animation': $props.indeterminate && isNaN($props.value)
    }])
  }, null, 6)], 512)), [[_vShow, $props.modelValue]]);
}