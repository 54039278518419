import API from '@/pages/shared/services/api';
import { filter, find } from 'lodash';
export default {
  namespaced: true,
  state: {
    company: null,
    personnel: [],
    connections: []
  },
  mutations: {
    SET_COMPANY(state, company) {
      state.company = company;
    },
    SET_personnel(state, personnel) {
      state.personnel = personnel;
    },
    SET_CONNECTIONS(state, connections) {
      state.connections = connections;
    }
  },
  actions: {
    setCompany({
      commit
    }, company) {
      commit('SET_COMPANY', company);
    },
    clearCompany({
      commit
    }) {
      commit('SET_COMPANY', null);
    },
    fetchCompany({
      commit
    }, id) {
      return API.get(`/companies/profile/${id}`).then(response => {
        commit('SET_COMPANY', response.data);
        return response.data;
      });
    },
    fetchpersonnel({
      commit
    }, id) {
      return API.get(`/companies/persons/${id}?persons=lookup`).then(response => {
        commit('SET_personnel', response.data);
        return response.data;
      });
    },
    fetchConnections({
      commit
    }, id) {
      return API.get(`/companies/connections/${id}`).then(response => {
        commit('SET_CONNECTIONS', response.data);
        return response.data;
      });
    }
  },
  getters: {
    getCompany: state => state.company,
    getpersonnel: state => state.personnel,
    getConnections: state => state.connections,
    accountingSystems: state => filter(state.connections, {
      externalSystemType: 'Accounting'
    }),
    payrollSystems: state => filter(state.connections, {
      externalSystemType: 'Payroll'
    }),
    getConnectedAccountingSystem: state => find(state.connections, {
      externalSystemType: 'Accounting',
      connected: true
    }),
    getConnectedPayrollSystem: state => find(state.connections, {
      externalSystemType: 'Payroll',
      connected: true
    }),
    connectedTo: state => id => (find(state.connections, {
      externalSystemID: id
    }) || find(state.connections, {
      externalSystemName: id
    }))?.connected ?? false
  }
};