import { useField } from "vee-validate";
import { toRef, watch } from "vue";
export default {
  name: "BaseInput",
  inheritAttrs: false,
  props: {
    modelValue: {},
    value: {},
    label: String,
    customPlaceholder: String,
    errorMessage: {
      type: String
    },
    warningMessage: {
      type: String
    },
    successMessage: {
      type: String
    },
    noDetails: Boolean,
    hint: String,
    refKey: {
      type: String,
      default: ""
    },
    rules: [String, Object, Array],
    immediate: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    active: {
      type: Boolean
    },
    disabled: {},
    readonly: {
      type: Boolean
    },
    autocomplete: {},
    align: {
      type: String,
      default: "left"
    },
    hidden: {
      type: Boolean
    }
  },
  setup(props, {
    emit
  }) {
    const {
      value: inputValue,
      errorMessage: error,
      handleBlur,
      handleChange,
      resetField,
      meta
    } = useField(toRef(props, "refKey"), props.rules ?? null, {
      initialValue: props.modelValue ?? props.value,
      validateOnMount: props.immediate,
      validateOnValueUpdate: props.immediate
    });
    resetField();
    watch(inputValue, val => {
      if (!props.readonly || !props.disabled) {
        emit("update:modelValue", val);
      }
    });
    const valChange = (eventName, event) => {
      emit(eventName, event?.target?.value);
      !props.readonly && emit("update:modelValue", event?.target?.value);
    };

    // to fully support two-way binding you sync the vee-validate ref when the prop changes
    watch(() => props.modelValue ?? props.value, v => v !== inputValue.value && (inputValue.value = v));
    return {
      inputValue,
      error,
      handleBlur,
      handleChange,
      meta,
      valChange
    };
  },
  emits: ["update:modelValue", "input", "blur", "focus", "change", "keydown", "keyup", "keypress"]
};