import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { ref } from "@vue/reactivity";
import { watch, toRef } from "@vue/runtime-core";
import { useField } from "vee-validate";
export default {
  name: "BasePhoneNumber",
  props: {
    modelValue: {
      type: String
    },
    value: {
      type: String
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    noDetails: {
      type: Boolean
    },
    errorMessage: {
      type: String
    },
    hint: {
      type: String
    },
    refKey: {
      type: String,
      default: ""
    },
    rules: [String, Object, Array],
    immediate: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    active: {
      type: Boolean
    }
  },
  components: {
    MazPhoneNumberInput
  },
  setup(props, {
    emit
  }) {
    const phoneNumber = ref(props.modelValue ?? props.value);
    const phoneNumberObj = ref({});
    const {
      value: inputValue,
      errorMessage: error,
      handleBlur,
      handleChange,
      resetField,
      meta,
      setTouched
    } = useField(toRef(props, "refKey"), props.rules ?? null, {
      initialValue: props.modelValue ?? props.value,
      validateOnMount: props.immediate,
      validateOnValueUpdate: props.immediate
      // standalone: !!props.rules,
    });
    resetField();
    // watch(inputValue, (val) => {
    // 	if (
    // 		!(attrs.readonly == "" || attrs.readonly) &&
    // 		props.disabled == false &&
    // 		props.readonly == false
    // 	) {
    // 		emit("update:modelValue", val);
    // 	}
    // });

    // to fully support two-way binding you sync the vee-validate ref when the prop changes
    watch(() => props.modelValue, v => v !== inputValue.value && (inputValue.value = v));
    watch(() => [props.modelValue, props.value], ([modelValue, value]) => {
      if (modelValue !== phoneNumber.value || value !== phoneNumber.value) phoneNumber.value = modelValue ?? value;
    });
    const phoneNumberUpdate = value => {
      phoneNumberObj.value = value;
      if (value && value.isValid) {
        emit("update:modelValue", value?.formatInternational);
      }
    };
    const handleBlur2 = () => {
      if (phoneNumber.value && !phoneNumberObj.value.isValid) {
        setTouched();
        emit("update:modelValue", phoneNumber.value);
      }
    };
    return {
      phoneNumber,
      phoneNumberUpdate,
      phoneNumberObj,
      inputValue,
      error,
      handleBlur,
      handleBlur2,
      handleChange,
      meta
    };
  }
};