import API from "@/pages/shared/services/api";
import SecureLS from 'secure-ls';
const SecureLocalStorage = new SecureLS({
  encodingType: 'aes'
});
export default {
  namespaced: true,
  state: {
    token: null,
    user: null
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    }
  },
  actions: {
    setToken({
      commit
    }, token) {
      commit('SET_TOKEN', token);
    },
    setUser({
      commit
    }, User) {
      commit('SET_USER', User);
    },
    logout({
      commit
    }) {
      commit('SET_TOKEN', null);
      window.localStorage.removeItem('vx');
      SecureLocalStorage.remove('vx');
      window.stop;
      window.location.href = process.env.VUE_APP_LOGIN_URL + '/logout';
    },
    fetchUser({
      commit
    }, companyID) {
      return new Promise((resolve, reject) => {
        API.get(`/users/getuser/${companyID}`).then(({
          data
        }) => {
          commit('SET_USER', data);
          resolve(data);
        }).catch(() => {
          reject();
        });
      });
    },
    async refreshToken({
      commit,
      dispatch
    }) {
      return new Promise((resolve, reject) => {
        API.get('/global/refreshtoken').then(({
          data
        }) => {
          commit("SET_TOKEN", data);
          resolve(data);
        }).catch(() => {
          reject();
          dispatch('logout');
        });
      });
    }
  },
  getters: {
    authed: state => state.token && state.user,
    user: state => state.user,
    token: state => state.token,
    hasRole: (state, getters) => role => {
      if (!getters.authed) return false;
      return (state.user.roles || []).includes(role);
    },
    hasRoles: (state, getters) => (...args) => {
      if (!getters.authed) return false;
      return args.some(role => (state.user.roles || []).includes(role));
    },
    isConsultant: state => state.user?.plan?.subscriptionType == "CONSULTANT"
  }
};