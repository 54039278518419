import format from "date-fns/format";
import Datepicker from "@vuepic/vue-datepicker";
import { map } from "lodash";
import { useField } from "vee-validate";
import { nextTick, toRef, watch } from "vue";
export default {
  name: "BaseDatePicker",
  inheritAttrs: false,
  extends: Datepicker,
  props: {
    modelValue: {},
    value: {},
    label: String,
    errorMessage: {
      type: String
    },
    warningMessage: {
      type: String
    },
    successMessage: {
      type: String
    },
    noDetails: Boolean,
    hint: String,
    required: {
      type: Boolean
    },
    noEmpty: {
      type: Boolean
    },
    refKey: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    }
  },
  emits: ["update:modelValue", "input", "blur", "focus", "change", "keydown", "keyup", "keypress"],
  setup(props, {
    emit
  }) {
    const {
      value: inputValue,
      errorMessage: error,
      handleBlur,
      handleChange,
      resetField,
      meta
    } = useField(toRef(props, "refKey"), props.rules ?? null, {
      initialValue: props.modelValue ?? props.value,
      validateOnMount: props.immediate,
      validateOnValueUpdate: props.immediate,
      standalone: !!props.rules
    });
    resetField();
    watch(inputValue, val => emit("update:modelValue", val));

    // to fully support two-way binding you sync the vee-validate ref when the prop changes
    watch(() => props.modelValue ?? props.value, v => v !== inputValue.value && (inputValue.value = v));
    const callOpenHandler = async () => {
      await nextTick();
    };
    const altPositionHandler = () => {
      // await nextTick();
      let el = document.querySelector(".dp__menu");
      let x = el.offsetTop;
      let y = el.offsetHeight;
      let z = document.body.offsetHeight;
      if (x + y > z) {
        let xx = x + y - z;
        return {
          /* prettier-ignore */
          top: `calc(${el.style.top} - ${xx + 10}px) !important`,
          left: el.style.left,
          transform: el.style.transform
        };
      }
      return {
        top: el.style.top,
        left: el.style.left,
        transform: el.style.transform
      };
    };
    return {
      inputValue,
      error,
      handleBlur,
      handleChange,
      resetField,
      meta,
      callOpenHandler,
      altPositionHandler
    };
  },
  created() {
    if (!this.modelValue && this.value) this.$emit("update:modelValue", this.value);
  },
  computed: {
    formatedDate() {
      if (!this.modelValue && !this.value && !this.noEmpty) return "";
      let x = format(new Date(this.$props.range ? this.modelValue[0] : this.modelValue ?? this.value ?? new Date()), "yyyy-MM-dd");
      let y;
      if (this.$props.range) y = format(new Date(this.modelValue?.[1] ?? this.modelValue ?? this.value), "yyyy-MM-dd");
      return x + (y ? " to " + y : "");
    }
  },
  methods: {
    formatDate: format,
    handleDate(event) {
      let x = format(new Date(event?.[0] ?? event), "yyyy-MM-dd");
      if (this.$props.range) x = map(event, e => format(new Date(e), "yyyy-MM-dd"));
      if (!this.$props.readonly || !this.$props.disabled) this.$emit("update:modelValue", x);
    }
  },
  components: {
    Datepicker
  },
  watch: {
    formatedDate: {
      handler(v) {
        if (!this.$props.readonly || !this.$props.disabled) this.$emit("update:modelValue", v);
      },
      immediate: true
    }
  }
};