import remove from "lodash/remove";
import find from "lodash/find";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
export default {
  namespaced: true,
  state: {
    messages: [],
    alerts: []
  },
  mutations: {
    SET_ALERT(state, payload) {
      let pl = {
        id: new Date().getTime(),
        text: payload.text ?? payload.message ?? '',
        dismisable: payload.dismisable ?? true,
        type: payload.type ?? 'warning'
      };
      state.alerts.unshift(pl);
    },
    DISMISS_ALERT(state, id) {
      remove(state.alerts, {
        id: id
      });
    },
    ADD_MESSAGE(state, payload) {
      state.messages.push(payload);
    },
    MARK_AS_READ(state, id) {
      let msg = find(state.messages, {
        messageID: id
      });
      msg.read = true;
      let x = findIndex(state.messages, {
        messageID: id
      });
      state.messages.splice(x, 1, msg);
    },
    DELETE_MESSAGE(state, id) {
      remove(state.messages, {
        messageID: id
      });
    }
  },
  actions: {
    createAlert({
      commit
    }, payload) {
      commit('SET_ALERT', payload);
    },
    dismissAlert({
      commit
    }, id) {
      commit('DISMISS_ALERT', id);
    },
    addMessage({
      commit
    }, payload) {
      commit('ADD_MESSAGE', payload);
    },
    markAsRead({
      commit
    }, id) {
      commit('MARK_AS_READ', id);
    },
    deleteMessage({
      commit
    }, id) {
      commit('DELETE_MESSAGE', id);
    },
    deleteMessages({
      commit
    }, ids) {
      ids.forEach(id => {
        commit('DELETE_MESSAGE', id);
      });
    }
  },
  getters: {
    getAlerts: state => state.alerts,
    getMessages: state => state.messages,
    getUnreadMessages: state => filter(state.messages, {
      read: false
    }),
    getReadMessages: state => filter(state.messages, {
      read: true
    }),
    getMessage: state => id => find(state.messages, {
      messageID: id
    })
  }
};