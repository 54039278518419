import ToastComponent from "./ToastComponent.vue";
import { createStore } from 'vuex';
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";
// import * as getters from './getters'

let store = createStore({});
let toastModule = {
  namespaced: true,
  state: {
    toastCount: 0,
    toasts: []
  },
  mutations: {
    SET_TOAST(state, payload) {
      let pl = {
        timeout: payload.timeout ?? 10000,
        type: payload.type ?? 'success',
        text: payload.text ?? payload.message ?? '',
        top: payload.top ?? false,
        left: payload.left ?? false,
        cancel: false,
        callback: payload.callback ?? null
      };
      pl.id = state.toastCount++;
      pl.bottom = pl.top ? false : payload.bottom ?? true;
      pl.right = pl.left ? false : payload.right ?? true;
      if (pl.bottom) state.toasts.push(pl);else state.toasts.unshift(pl);
      setTimeout(() => {
        let x = findIndex(state.toasts, {
          id: pl.id
        });
        state.toasts.splice(x, 1);
        if (pl.callback) pl.callback();
      }, pl.timeout);
    },
    CANCEL_TOAST(state, id) {
      remove(state.toasts, {
        id: id
      });
    }
  },
  actions: {
    createToast({
      commit
    }, payload) {
      commit('SET_TOAST', payload);
    },
    cancelToast({
      commit
    }, id) {
      commit('CANCEL_TOAST', id);
    }
  },
  getters: {
    getToasts: state => state.toasts
  }
};
export default {
  install: app => {
    // options;
    if (!app.config.globalProperties.$store) app.use(store);
    app.config.globalProperties.$store.registerModule('toast', toastModule);
    app.config.globalProperties.$toast = opt => {
      app.config.globalProperties.$store.dispatch('toast/createToast', opt);
    };
    app.component('toast-toaster', ToastComponent);
    app.provide('toast', app.config.globalProperties.$toast);
  }
};