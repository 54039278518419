import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const toasts = computed(() => store.getters["toast/getToasts"]);
    return {
      toasts
    };
  }
};