import auth from "./modules/auth";
import companies from "./modules/companies";
import lookups from "./modules/lookups";
import messages from "./modules/messages";
import logrocketVuexPlugin from "@/pages/shared/plugins/logrocket/logrocketVuexPlugin";
import { createStore } from 'vuex';
import VuexPersister from "./persister";
import SecureLS from 'secure-ls';
const SecureLocalStorage = new SecureLS({
  encodingType: 'aes'
}); // AES encryption and data compression
/* Can also accept other options as below:
* new SecureLS({encodingType: '', isCompression: false})
* new SecureLS({isCompression: false})
* SecureLS({encodingType: 'rc4', isCompression: false})
* new SecureLS({encodingType: 'rc4', isCompression: false, encryptionSecret: 's3cr3tPa$$w0rd@123'})
* More details are found here (https://www.npmjs.com/package/secure-ls) */

// JavaScript
const authPersister = new VuexPersister({
  // new VuexPersister<State> with TypeScript
  key: 'vx',
  statesToPersist: ['auth.token', 'auth.user'],
  storage: {
    getItem: key => SecureLocalStorage.get(key),
    setItem: (key, value) => SecureLocalStorage.set(key, value),
    removeItem: key => SecureLocalStorage.remove(key),
    length: SecureLocalStorage.getAllKeys().length,
    clear: () => SecureLocalStorage.clear(),
    key: key => key
  }
});
export default createStore({
  state: {
    modal: false
  },
  getters: {
    getModal: state => state.modal
  },
  mutations: {
    SET_MODAL(state, payload) {
      state.modal = payload;
    }
  },
  actions: {
    toggleModal({
      commit
    }, payload) {
      commit("SET_MODAL", payload);
    }
  },
  modules: {
    auth,
    companies,
    lookups,
    messages
  },
  plugins: [authPersister.persist, logrocketVuexPlugin]
});