import API from '@/pages/shared/services/api';
import { filter } from 'lodash';
import orderBy from 'lodash/orderBy';
export default {
  namespaced: true,
  state: {
    disabilities: {},
    ethnicities: {},
    unions: {},
    educational_institutions: {},
    countries: {},
    ofo_codes: {},
    employmentStatuses: {},
    nqf_levels: {},
    education_levels: {},
    municipalities: {},
    shareClasses: {},
    occupational_levels: {},
    SETAClassifications: {},
    industrySectors: {},
    bargaining_councils: {},
    business_types: {},
    subscription_plans: {},
    userTitles: {},
    userRoles: {},
    activeRoles: {},
    assignableRoles: {}
  },
  mutations: {
    SET_DISABILITIES(state, disabilities) {
      state.disabilities = disabilities;
    },
    SET_ETHNICITIES(state, ethnicities) {
      state.ethnicities = ethnicities;
    },
    SET_UNIONS(state, unions) {
      state.unions = unions;
    },
    SET_EDUCATIONAL_INSTITUTIONS(state, educational_institutions) {
      state.educational_institutions = educational_institutions;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_OFO_CODES(state, ofo_codes) {
      state.ofo_codes = ofo_codes;
    },
    SET_EMPLOYMENTSTATUSES(state, employmentStatuses) {
      state.employmentStatuses = employmentStatuses;
    },
    SET_NQF_LEVELS(state, nqf_levels) {
      state.nqf_levels = nqf_levels;
    },
    SET_EDUCATION_LEVELS(state, education_levels) {
      state.education_levels = education_levels;
    },
    SET_MUNICIPALITIES(state, municipalities) {
      state.municipalities = municipalities;
    },
    SET_SHARECLASSES(state, shareClasses) {
      state.shareClasses = shareClasses;
    },
    SET_OCCUPATIONAL_LEVELS(state, occupational_levels) {
      state.occupational_levels = occupational_levels;
    },
    SET_SETACLASSIFICATIONS(state, SETAClassifications) {
      state.SETAClassifications = SETAClassifications;
    },
    SET_INDUSTRYSECTORS(state, industrySectors) {
      state.industrySectors = industrySectors;
    },
    SET_BARGAINING_COUNCILS(state, bargaining_councils) {
      state.bargaining_councils = bargaining_councils;
    },
    SET_BUSINESS_TYPES(state, business_types) {
      state.business_types = business_types;
    },
    SET_SUBSCRIPTION_PLANS(state, subscription_plans) {
      state.subscription_plans = subscription_plans;
    },
    SET_USERTITLES(state, userTitles) {
      state.userTitles = userTitles;
    },
    SET_USERROLES(state, userRoles) {
      state.userRoles = userRoles;
    },
    SET_ACTIVEROLES(state, activeRoles) {
      state.activeRoles = activeRoles;
    },
    SET_ASSIGNABLEROLES(state, assignableRoles) {
      state.assignableRoles = assignableRoles;
    }
  },
  actions: {
    fetchDisabilities({
      commit
    }) {
      return API.get('/lookups/disabilities').then(response => {
        commit('SET_DISABILITIES', response.data);
        return response.data;
      });
    },
    fetchEthnicities({
      commit
    }) {
      return API.get('/lookups/ethnicities').then(response => {
        commit('SET_ETHNICITIES', response.data);
        return response.data;
      });
    },
    fetchUnions({
      commit
    }) {
      return API.get('/lookups/unions').then(response => {
        commit('SET_UNIONS', response.data);
        return response.data;
      });
    },
    fetchEducationalInstitutions({
      commit
    }) {
      return API.get('/lookups/educational_institutions').then(response => {
        commit('SET_EDUCATIONAL_INSTITUTIONS', response.data);
        return response.data;
      });
    },
    fetchCountries({
      commit
    }) {
      return API.get('/lookups/countries').then(response => {
        commit('SET_COUNTRIES', response.data);
        return response.data;
      });
    },
    fetchOFOCodes({
      commit
    }) {
      return API.get('/lookups/ofo_codes').then(response => {
        commit('SET_OFO_CODES', response.data);
        return response.data;
      });
    },
    fetchEmploymentStatuses({
      commit
    }) {
      return API.get('/lookups/employment_statuses').then(response => {
        commit('SET_EMPLOYMENTSTATUSES', response.data);
        return response.data;
      });
    },
    fetchNQFLevels({
      commit
    }) {
      return API.get('/lookups/nqf_levels').then(response => {
        commit('SET_NQF_LEVELS', response.data);
        return response.data;
      });
    },
    fetchEducationLevels({
      commit
    }) {
      return API.get('/lookups/education_levels').then(response => {
        commit('SET_EDUCATION_LEVELS', response.data);
        return response.data;
      });
    },
    fetchMunicipalities({
      commit
    }) {
      return API.get('/lookups/municipalities').then(response => {
        commit('SET_MUNICIPALITIES', response.data);
        return response.data;
      });
    },
    fetchShareClasses({
      commit
    }) {
      return API.get('/lookups/share_classes').then(response => {
        commit('SET_SHARECLASSES', response.data);
        return response.data;
      });
    },
    fetchOccupationalLevels({
      commit
    }) {
      return API.get('/lookups/occupation_levels').then(response => {
        commit('SET_OCCUPATIONAL_LEVELS', response.data);
        return response.data;
      });
    },
    fetchSETAClassifications({
      commit
    }) {
      return API.get('/lookups/seta_classifications').then(response => {
        commit('SET_SETACLASSIFICATIONS', response.data);
        return response.data;
      });
    },
    fetchIndustrySectors({
      commit
    }) {
      return API.get('/lookups/industry_sectors').then(response => {
        commit('SET_INDUSTRYSECTORS', response.data);
        return response.data;
      });
    },
    fetchBargainingCouncils({
      commit
    }) {
      return API.get('/lookups/bargaining_councils').then(response => {
        commit('SET_BARGAINING_COUNCILS', response.data);
        return response.data;
      });
    },
    fetchBusinessTypes({
      commit
    }) {
      return API.get('/lookups/business_types').then(response => {
        commit('SET_BUSINESS_TYPES', response.data);
        return response.data;
      });
    },
    fetchSubscriptionPlans({
      commit
    }) {
      return API.get('/lookups/plans').then(response => {
        commit('SET_SUBSCRIPTION_PLANS', response.data);
        return response.data;
      });
    },
    fetchUserTitles({
      commit
    }) {
      return API.get('/lookups/user_titles').then(response => {
        commit('SET_USERTITLES', response.data);
        return response.data;
      });
    },
    fetchUserRoles({
      commit
    }) {
      return API.get('/lookups/user_roles').then(response => {
        commit('SET_USERROLES', response.data);
        return response.data;
      });
    }
  },
  getters: {
    disabilities: state => state.disabilities,
    ethnicities: state => state.ethnicities,
    unions: state => state.unions,
    educational_institutions: state => state.educational_institutions,
    countries: state => state.countries,
    ofo_codes: state => state.ofo_codes,
    employmentStatuses: state => state.employmentStatuses,
    nqf_levels: state => state.nqf_levels,
    education_levels: state => state.education_levels,
    municipalities: state => state.municipalities,
    shareClasses: state => state.shareClasses,
    occupational_levels: state => state.occupational_levels,
    SETAClassifications: state => state.SETAClassifications,
    industrySectors: state => state.industrySectors,
    bargaining_councils: state => state.bargaining_councils,
    business_types: state => state.business_types,
    subscription_plans: state => state.subscription_plans,
    userTitles: state => state.userTitles,
    userRoles: state => state.userRoles,
    activeRoles: state => filter(state.userRoles, {
      hidden: false,
      editable: true
    }),
    assignableRoles: state => orderBy(filter(state.userRoles, {
      assignable: true
    }), ['priority'], ['asc'])
  }
};