import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ec0a308"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pni w-full relative z-[2]"
};
const _hoisted_2 = {
  key: 0,
  class: "absolute top-0 right-1 text-red-600 font-bold z-10"
};
const _hoisted_3 = {
  key: 0,
  class: "text-red-500"
};
const _hoisted_4 = {
  key: 1,
  class: "text-grey-300"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MazPhoneNumberInput = _resolveComponent("MazPhoneNumberInput");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.required ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*")) : _createCommentVNode("", true), _createVNode(_component_MazPhoneNumberInput, {
    class: _normalizeClass([{
      ' shadow-md shadow-primary-400': $props.active
    }, "w-full !text-sm z-5"]),
    modelValue: $setup.phoneNumber,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.phoneNumber = $event),
    modelModifiers: {
      lazy: true
    },
    label: $props.label ?? 'Phone Number',
    placeholder: "",
    disabled: $props.disabled,
    "default-country-code": "ZA",
    onUpdate: _cache[1] || (_cache[1] = $event => $setup.phoneNumberUpdate($event)),
    onBlur: _cache[2] || (_cache[2] = $event => {
      $setup.handleBlur();
      $setup.handleBlur2();
    }),
    onChange: $setup.handleChange
  }, null, 8, ["class", "modelValue", "label", "disabled", "onChange"]), _withDirectives(_createElementVNode("div", {
    class: _normalizeClass(["text-xs font-semibold my-1 px-2 messages", $props.errorMessage || $props.hint || $setup.error ? 'min-h-4' : 'h-4'])
  }, [_renderSlot(_ctx.$slots, "messages", {}, () => [_createVNode(_Transition, {
    name: "fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [$setup.error ?? $props.errorMessage ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.error ?? $props.errorMessage), 1)) : $props.hint ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.hint), 1)) : _createCommentVNode("", true)]),
    _: 1
  })], true)], 2), [[_vShow, !$props.noDetails || $props.errorMessage || $props.hint || $setup.error]])]);
}