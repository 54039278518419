import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-426a0903"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "w-full flex justify-between"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.toasts, (a, i) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["alert absolute shadow-lg z-[1001] rounded max-w-md text-white font-semibold text-sm transition ease-in-out", {
        'left-0.5': a.left,
        'right-0.5': a.right,
        'bg-red-400': a.type == 'error',
        'bg-amber-500': a.type == 'warning',
        'bg-tertiary-600': a.type == 'success',
        '!bg-primary-500': a.type == 'info'
      }]),
      key: i,
      style: _normalizeStyle({
        top: a.top ? `${i * 60 + 8}px` : null,
        bottom: a.bottom ? `${i * 60 + 8}px` : null
      })
    }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", {
      innerHTML: a.text
    }, null, 8, _hoisted_2), _createElementVNode("button", {
      onClick: $event => _ctx.$store.dispatch('toast/cancelToast', a.id),
      class: "rounded-full p-1 border-2 border-white btn-outline transition-all duration-200 ease-in-out top-2 right-1 z-50"
    }, [_createVNode(_component_XMarkIcon, {
      class: "w-3 h-3 fill-white"
    })], 8, _hoisted_3)])], 6);
  }), 128);
}